// TempData
import TempDataNavBar from '../TempData_NavBar';
import TempDataMegaMenu from '../TempData_MegaMenu';
import TempDataFooter from '../TempData_footer';
import * as ColorGuide from '../../../cssGuides/colorGuide';

// Page Data
const Data = {
  pageName: 'Tabels Test Page',
  pageTemplate: 'Template2',
  pageData: {
    nav: TempDataNavBar,
    megaMenu: TempDataMegaMenu,
    footer: TempDataFooter,
    level_1_Id: 'Page_1',
    pageTitle: 'HTML render test page',
    pageDescription: 'Tester component - HTML render',
    bannerImage: null, // accepts null
    bannerContent: null, // accepts null
    bottomContent: [
      {
        componentName: 'BlockArea',
        componentData: {
          backgroundColor: ColorGuide.aqua,
        },
        componentChildren: [
          {
            componentName: 'CollapsibleTextArea',
            componentData: {
              id: 'first',
              openText: 'Læs mere',
              closeText: 'Læs mindre',
              text: [
                {
                  componentName: 'HtmlRender',
                  componentData: {
                    html:
                      '<h2>Header Text</h2><p>Eget nunc scelerisque viverra mauris in aliquam. Massa ultricies mi quis hendrerit. Enim nulla aliquet porttitor lacus. Neque laoreet suspendisse interdum consectetur libero id. Sapien pellentesque habitant morbi tristique senectus pulvinar neque laoreet suspendisse. Pellentesque nec nam aliquam sem et. Elit duis tristique sollicitudin nibh sit amet commodo. Mi proin sed libero enim sed faucibus turpis in.</p>',
                  },
                },
              ],
            },
            componentChildren: [
              {
                componentName: 'HtmlRender',
                componentData: {
                  html:
                    '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sit amet consectetur adipiscing elit duis tristique sollicitudin nibh sit. Auctor urna </p>',
                },
              },
              {
                componentName: 'HtmlTable',
                componentData: {
                  insideCollapsible: false,
                  classType: 'table_layout_right',
                  thead: [
                    {
                      classType: '',
                      tr: [
                        {
                          classType: '',
                          value: '',
                        },
                        {
                          classType: '',
                          value: '<strong>2017</strong>',
                        },
                        {
                          classType: '',
                          value: '<strong>2016</strong>',
                        },
                        {
                          classType: '',
                          value: '<strong>2015</strong>',
                        },
                        {
                          classType: '',
                          value: '<strong>2014</strong>',
                        },
                      ],
                    },
                  ],
                  tbody: [
                    {
                      classType: '',
                      tr: [
                        {
                          classType: '',
                          value: '<p>Præmier i alt</p>',
                        },
                        {
                          classType: '',
                          value: '8.784',
                        },
                        {
                          classType: '',
                          value: '8.784',
                        },
                        {
                          classType: '',
                          value: '8.784',
                        },
                        {
                          classType: '',
                          value: '9.017',
                        },
                      ],
                    },
                    {
                      classType: '',
                      tr: [
                        {
                          classType: '',
                          value: '<p>Udbetalte ydelser i alt</p>',
                        },
                        {
                          classType: '',
                          value: '8.646',
                        },
                        {
                          classType: '',
                          value: '8.646',
                        },
                        {
                          classType: '',
                          value: '8.646',
                        },
                        {
                          classType: '',
                          value: '8.100',
                        },
                      ],
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
    desktopTools: {}, // accepts null
  },
  pageContent: [
    {
      componentName: 'HtmlTable',
      componentData: {
        classType: '',
        thead: [
          {
            classType: '',
            tr: [
              {
                classType: '',
                value: 'left/default',
              },
              {
                classType: '',
                value: '<strong>2017</strong>',
              },
              {
                classType: '',
                value: '<strong>2016</strong>',
              },
              {
                classType: '',
                value: '<strong>2015</strong>',
              },
              {
                classType: '',
                value: '<strong>2014</strong>',
              },
            ],
          },
        ],
        tbody: [
          {
            classType: '',
            tr: [
              {
                classType: '',
                value: '<p>Præmier i alt</p>',
              },
              {
                classType: '',
                value: '8.784',
              },
              {
                classType: '',
                value: '8.784',
              },
              {
                classType: '',
                value: '8.784',
              },
              {
                classType: '',
                value: '9.017',
              },
            ],
          },
          {
            classType: '',
            tr: [
              {
                classType: '',
                value: '<p>Udbetalte ydelser i alt</p>',
              },
              {
                classType: '',
                value: '8.646',
              },
              {
                classType: '',
                value: '8.646',
              },
              {
                classType: '',
                value: '8.646',
              },
              {
                classType: '',
                value: '8.100',
              },
            ],
          },
        ],
      },
    },
    {
      componentName: 'HtmlTable',
      componentData: {
        classType: 'table_layout_center',
        thead: [
          {
            classType: '',
            tr: [
              {
                classType: '',
                value: 'center',
              },
              {
                classType: '',
                value: '<strong>2017</strong>',
              },
              {
                classType: '',
                value: '<strong>2016</strong>',
              },
              {
                classType: '',
                value: '<strong>2015</strong>',
              },
              {
                classType: '',
                value: '<strong>2014</strong>',
              },
            ],
          },
        ],
        tbody: [
          {
            classType: '',
            tr: [
              {
                classType: '',
                value: '<p>Præmier i alt</p>',
              },
              {
                classType: '',
                value: '8.784',
              },
              {
                classType: '',
                value: '8.784',
              },
              {
                classType: '',
                value: '8.784',
              },
              {
                classType: '',
                value: '9.017',
              },
            ],
          },
          {
            classType: '',
            tr: [
              {
                classType: '',
                value: '<p>Udbetalte ydelser i alt</p>',
              },
              {
                classType: '',
                value: '8.646',
              },
              {
                classType: '',
                value: '8.646',
              },
              {
                classType: '',
                value: '8.646',
              },
              {
                classType: '',
                value: '8.100',
              },
            ],
          },
        ],
      },
    },
    {
      componentName: 'HtmlTable',
      componentData: {
        classType: 'table_layout_right',
        thead: [
          {
            classType: '',
            tr: [
              {
                classType: '',
                value: 'right',
              },
              {
                classType: '',
                value: '<strong>2017</strong>',
              },
              {
                classType: '',
                value: '<strong>2016</strong>',
              },
              {
                classType: '',
                value: '<strong>2015</strong>',
              },
              {
                classType: '',
                value: '<strong>2014</strong>',
              },
            ],
          },
        ],
        tbody: [
          {
            classType: '',
            tr: [
              {
                classType: '',
                value: '<p>Præmier i alt</p>',
              },
              {
                classType: '',
                value: '8.784',
              },
              {
                classType: '',
                value: '8.784',
              },
              {
                classType: '',
                value: '8.784',
              },
              {
                classType: '',
                value: '9.017',
              },
            ],
          },
          {
            classType: '',
            tr: [
              {
                classType: 'text-right',
                value: '<p>Udbetalte ydelser i alt</p>',
              },
              {
                classType: '',
                value: '8.646',
              },
              {
                classType: '',
                value: '8.646',
              },
              {
                classType: '',
                value: '8.646',
              },
              {
                classType: '',
                value: '8.100',
              },
            ],
          },
        ],
      },
    },
  ],
};

export default Data;
